import axios from 'axios'
import store from '../store';
import { Message } from 'element-ui';

// dev环境
// const domain = 'https://ophthalmology-api.gymooit.cn/';
const domain = 'https://api.optionehk.com/';
// const globalBaseURL = `https://ophthalmology-api.gymooit.cn/`;
const globalBaseURL = `https://api.optionehk.com/`;

// pro环境
// const domain = 'https://jkbx-admin.91jiexun.com';
// const globalBaseURL = `https://jkbx-api.91jiexun.com/api/v1`;

const fileBaseURL = 'https://gymoo-project-cdn.oss-cn-shenzhen.aliyuncs.com/';
// 创建axios实例
const service = axios.create({
	//基本路径
	baseURL: globalBaseURL,
	timeout: 60 * 2000,
	crossDomain: true,
	withCredentials:false
})

// 请求拦截器
service.interceptors.request.use(
	(req) => {
		const token = store.state.TOKEN || '';
		if(token){
			req.headers ={'Authorization':'Bearer ' + token};
		}
		// 请求等待
		return req
	},
	(err) => {
		return Promise.reject(err)
	}
);

// 响应拦截器
service.interceptors.response.use(
	(req) => {
		if(req.data.code && req.data.code != 200){
			Message.error(req.data.msg);
		}
		return req
	},
	(err) => {
		if(err.response.data.code == 401 || err.response.data.code == 403){
			Message.error(err.response.data.msg);
			window.vm.$router.push('/login')
			return false;
		}else{
			Message.error(err.response.data.msg);
		}
		return Promise.reject(err.response);
	}
);

//封装请求updatePost方法
function updatePost(url, data) {
	return new Promise((reslove, reject) => {
		service({
			method: "post",
			url: `${url}`,
			data: data, //java后台用qs转
			// config: Object.assign({}, config),
			headers: {'Content-Type':'multipart/form-data'}
		})
		.then(r => {
			reslove(r.data);
		})
		.catch(e => {
			reject(e);
		});
	});
}

//封装请求Post方法
function formPost(url, data) {
	return new Promise((reslove, reject) => {
		service({
			method: "post",
			url: `${url}`,
			data: data, //java后台用qs转
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(r => {
			reslove(r.data);
		})
		.catch(e => {
			reject(e);
		});
	});
}

//封装请求put方法
function formPut(url, data) {
	return new Promise((reslove, reject) => {
		service({
			method: "put",
			url: `${url}`,
			data: data, //java后台用qs转
			// config: Object.assign({}, config),
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(r => {
			reslove(r.data);
		})
		.catch(e => {
			reject(e);
		});
	});
}

//封装请求put方法
function formDelete(url, data) {
	return new Promise((reslove, reject) => {
		service({
			method: "delete",
			url: `${url}`,
			data: data, //java后台用qs转
			// config: Object.assign({}, config),
			headers: {
				'Content-Type': 'application/json;charset=UTF-8'
			}
		})
		.then(r => {
			reslove(r.data);
		})
		.catch(e => {
			reject(e);
		});
	});
}

//封装请求Get方法
function formGet(url, data) {
	let params = Object.assign({}, data);
	if(url == '/auth/menu'){
		params = data;
	}
	return new Promise((reslove, reject) => {
		service({
			method: "get",
			url: `${url}`,
			params: params
		})
		.then(r => {
			reslove(r.data);
		})
		.catch(e => {
			reject(e);
		});
	});
}

function ajaxByte(url, data, type) {
	return new Promise((reslove, reject) => {
		let postData = {};
		postData.method = type;
		postData.url = `${url}`;
		postData.responseType = 'blob';
		if(type == 'get'){
			postData.params = data;
		}else{
			postData.data = data
		}
		service(postData)
			.then(r => {
				console.log(r)
				//rst.data是返回的blob对象
				//application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示doc类型
                var blob = new Blob([r.data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'}); 
				//从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
				var contentDisposition = r.headers['content-disposition'];
				// var patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
				// var result = patt.exec(contentDisposition);
				// var filename = result[1];
				var filename = new Date().getTime() + '_exec.csv';
				var downloadElement = document.createElement('a');
				//创建下载的链接
				var href = window.URL.createObjectURL(blob);
				downloadElement.style.display = 'none';
				downloadElement.href = href;
				//下载后文件名
				downloadElement.download =filename ;
				document.body.appendChild(downloadElement);
				//点击下载
				downloadElement.click(); 
				//下载完成移除元素
				document.body.removeChild(downloadElement);
				//释放掉blob对象
				window.URL.revokeObjectURL(href);
				reslove(r);
			})
			.catch(e => {
				console.log(e)
				reject(e);
			});
	});
}


export default {
	updatePost: (url, data) => updatePost( url, data),
	post: (url, data) => formPost( url, data),
	put: (url, data) => formPut( url, data),
	del: (url, data) => formDelete( url, data),
	get: (url, data) => formGet( url, data),
	execDown: (url,data,type) => ajaxByte( url, data, type),
	service,
	baseURL: globalBaseURL,
	fileUrl:fileBaseURL,
	domain:domain,
};